<template>
  <v-container id="dashboard" fluid tag="section">
    <v-container>
      <dashboard-core-app-bar />
    </v-container>
    <ChartWidgets />
    <v-row style="margin-top: 50px">
      <v-card class="mt-4 mx-auto" elevation="24" shaped>
        <v-col cols="12" lg="8">
          <stockProduits />
        </v-col>
      </v-card>
      <!--
      On désactive ChartListProduits car il est pas optimisé
      <v-card class="mt-4 mx-auto" elevation="24" shaped>
        <v-col cols="12" lg="4">
          <ListProduits />
        </v-col>
      </v-card>
      -->
      <v-card class="mt-4 mx-auto" elevation="24" shaped>
        <v-col cols="12" lg="4">
          <StatutProduits />
        </v-col>
      </v-card>
      <v-card class="mt-4 mx-auto" elevation="24" shaped>
        <v-col cols="12" lg="4">
          <StatutCommande />
        </v-col>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashboardDashboard",
  components: {
    DashboardCoreAppBar: () =>
      import("../materialDashboard/components/core/AppBar.vue"),
    StatutProduits: () => import("./components/core/ChartStatutProduits"),
    stockProduits: () => import("./components/core/ChartStockProduits"),
    // On désactive ChartListProduits car il est pas optimisé
    //ListProduits: () => import("./components/core/ChartListProduits"),
    ChartWidgets: () => import("./components/core/ChartWidgets"),
    StatutCommande: () => import("./components/core/ChartStatutCommande.vue"),
  },
  data() {
    return {};
  },

  methods: {},
  beforeMount() {},
};
</script>
